@import "~antd/dist/antd.css";

.logo {
	height: 32px;
	background: rgba(255, 255, 255, 0.3);
	margin: 16px;
}

.site-layout .site-layout-background {
	background: #fff;
	padding: 20px;
	border-radius: 5px;
	// box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.37);
}

.ant-spin-nested-loading > div > .ant-spin {
	background: #ffffff63 !important;
}

.custom-tab-builder {
	background: white !important;
	-webkit-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.11);
	-moz-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.11);
	box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.11);
	.ant-tabs-tab {
		border-color: #f3f3f3 !important;
		padding: 20px 40px !important;
	}
	.ant-tabs-content-holder {
		padding: 20px;
	}
}

.page-title-wrapper {
	font-size: 22px;
	font-weight: bold;

	&::placeholder {
		color: rgba(128, 128, 128, 0.226);
		font-weight: normal;
		font-size: 18px;
	}
}

.page-builder {
	.ant-tabs-content-holder {
		min-height: 80vh;
	}
}

.preview--wrapper {
	p {
		font-size: 18px;
	}
	blockquote {
		font-size: 1.4em;
		width: 90%;
		margin: 50px auto;
		font-family: Open Sans;
		font-style: italic;
		color: #555555;
		padding: 1.2em 30px 1.2em 75px;
		border-left: 8px solid #78c0a8;
		line-height: 1.6;
		position: relative;
		background: #ededed;
	}

	blockquote::before {
		font-family: Arial;
		content: "\201C";
		color: #78c0a8;
		font-size: 4em;
		position: absolute;
		left: 10px;
		top: -10px;
	}

	blockquote::after {
		content: '';
	}

	blockquote span {
		display: block;
		color: #333333;
		font-style: normal;
		font-weight: bold;
		margin-top: 1em;
	}
}

.custom__form {
	&.ant-form {
		input[type='text'],
		input[type='password'],
		input[type='number'],
		input[type='email'],
		.ant-select-selector,
		.ant-picker,
		textarea {
			-webkit-appearance: none;
			background: #f0f2fc;
		}
		.ant-form-item {
			margin-bottom: 0px;
		}
	}
}
