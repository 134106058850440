.full-screen {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(255, 255, 255, 0.582);
	z-index: 100;
}

.form__modal .ant-modal-content {
	overflow: hidden;
}
