.dashboard__card__wrapper {
	// background-color: #6b0f1a;
	// background-image: linear-gradient(315deg, #6b0f1a 0%, #b91372 74%);
	background: white;
	color: black;
	border-radius: 20px;
	// min-height: 100px;
	// height: calc(100% - 30px);
	// margin: 20px 0px;
	position: relative;
	padding-top: 30px;
	margin-top: 40px;
	box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05), 0 15px 40px rgba(166, 173, 201, 0.2);
	h5 {
		color: black;
		text-transform: uppercase;
	}
	h1 {
		font-weight: bold;
		margin-bottom: 0px;
	}
	.icon {
		position: absolute;
		top: 0;
		background: #eff2f5;
		padding: 5px;
		border-radius: 5px;
		transform: translateY(-50%);
	}
}
