.my-header {
  .my-profile {
    padding: 0px 20px;
    cursor: pointer;
    &:hover {
      background: rgb(218, 218, 218);
      transition: 0.5s ease-in-out all;
    }
  }
}
.profile-header {
  min-height: 100px;
  padding: 10px 20px;
}
