.ant-carousel .slick-prev,
.ant-carousel .slick-next {
	font-size: 35px;
}
.attendance__slider {
	.slick-prev {
		left: -60px;
	}
	.slick-next {
		right: -45px;
	}
}
